// routes
import Router from './routes'
// theme
import ThemeProvider from './theme'
// components
import ThemeSettings from './components/settings'
import { ChartStyle } from './components/chart'
import ScrollToTop from './components/ScrollToTop'
import { ProgressBarStyle } from './components/ProgressBar'
import NotistackProvider from './components/NotistackProvider'
import MotionLazyContainer from './components/animate/MotionLazyContainer'
import Web3Provider from '~/components/Web3Provider'
import { UserActionProvide } from './contexts/UserActionContext'
import { SafeboxProvide } from './contexts/SafeboxContext'
import { ChainProvide } from './contexts/ChainContext'

// ----------------------------------------------------------------------

export default function App() {
    return (
        <ChainProvide>
            <Web3Provider>
                <MotionLazyContainer>
                    <ThemeProvider>
                        <ThemeSettings>
                            <NotistackProvider>
                                <SafeboxProvide>
                                    <UserActionProvide>
                                        <ProgressBarStyle />
                                        <ChartStyle />
                                        <ScrollToTop />
                                        <Router />
                                    </UserActionProvide>
                                </SafeboxProvide>
                            </NotistackProvider>
                        </ThemeSettings>
                    </ThemeProvider>
                </MotionLazyContainer>
            </Web3Provider>
        </ChainProvide>
    )
}
