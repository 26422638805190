import { Outlet } from 'react-router-dom'
import { useContext } from 'react'
import { UserActionContext } from '~/contexts/UserActionContext'
// @mui
import { Stack } from '@mui/material'
// components
// import Logo from '../../components/Logo'
//
import LoadingScreen from '~/components/LoadingScreen'
import MainFooter from './MainFooter'
import MainHeader from './MainHeader'
// ----------------------------------------------------------------------

export default function MainLayout() {
    const { isLoading } = useContext(UserActionContext)
    // const { pathname } = useLocation()

    // console.log(UserActionContext)
    // const isHome = pathname === '/'
    return (
        <Stack sx={{ minHeight: 1, bgcolor: 'rgb(248, 251, 251)' }}>
            <MainHeader />
            {/* <Box sx={{ flexGrow: 1 }}> */}
            <Outlet />
            <MainFooter />
            {isLoading ? <LoadingScreen /> : null}
            {/* </Box> */}

            {/* <Box sx={{ flexGrow: 1 }} />
            <Box
                sx={{
                    py: 5,
                    textAlign: 'center',
                    position: 'relative',
                    bgcolor: 'background.default',
                }}
            >
                <Container>
                    <Logo sx={{ mb: 1, mx: 'auto' }} />

                    <Typography variant="caption" component="p">
                            © All rights reserved
                        <br /> made by &nbsp;
                        <Link href="https://minimals.cc/">minimals.cc</Link>
                    </Typography>
                </Container>
            </Box> */}
            {/* {!isHome ? (
                <MainFooter />
            ) : (
                <Box
                    sx={{
                        py: 5,
                        textAlign: 'center',
                        position: 'relative',
                        bgcolor: 'background.default',
                    }}
                >
                    <Container>
                        <Logo sx={{ mb: 1, mx: 'auto' }} />

                        <Typography variant="caption" component="p">
                            © All rights reserved
                            <br /> made by &nbsp;
                            <Link href="https://minimals.cc/">minimals.cc</Link>
                        </Typography>
                    </Container>
                </Box>
            )} */}
        </Stack>
    )
}
