// import { useLocation } from 'react-router-dom'
import React, { useCallback, useEffect, useState, useContext } from 'react'
// @mui
import { Link } from 'react-router-dom'
import { styled, useTheme, alpha } from '@mui/material/styles'
import { Stack, Button, AppBar, Toolbar, Container, Menu, Typography, MenuItem ,DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
    Box,
} from '@mui/material'
import { MenuProps } from '@mui/material/Menu'
import { ButtonProps } from '@mui/material/Button'
import Image from '~/components/Image'
// import iconify from '~/components/Iconify'
import ZksafeIcon from '~/assets/zkSafe_logo0.png'
import MetaMaskIcon from '~/assets/MetaMask.svg'
import { shortAccount } from '~/utils/formatString'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
// import FabButtonAnimate from '~/components/FabButtonAnimate'
// hooks
import useOffSetTop from '~/hooks/useOffSetTop'
import { UserActionContext } from '~/contexts/UserActionContext'
import { NetworkType, getAddChainParameters, chainIsEnable, getChainIcon,getChainNetworkInfo } from '~/config/chains'
import { hooks, metaMask } from '~/connectors/metaMask'
import useSafeboInfo from '~/hooks/useSafeboxInfo'
import { useContracts } from '~/hooks/useContracts'
// import { hooks, network } from '~/connectors/network'
// import useResponsive from '~/hooks/useResponsive'
// utils
import cssStyles from '~/utils/cssStyles'
// config
import { HEADER } from '~/config'
// components
// import Logo from '~/components/Logo'
import Logo from '~/assets/zkSafe_logo2.png'
// import { array } from 'yup'
// import { validate } from 'numeral'

// import useSafebox from '~/hooks/useSafebox'
// import Label from '~/components/Label'
//
// import MenuDesktop from './MenuDesktop'
// import MenuMobile from './MenuMobile'
// import navConfig from './MenuConfig'

// ----------------------------------------------------------------------


const { useAccounts, useChainId, useIsActivating } = hooks



const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    height: HEADER.MOBILE_HEIGHT,
    transition: theme.transitions.create(['height', 'background-color'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('md')]: {
        height: HEADER.MAIN_DESKTOP_HEIGHT,
    },
}))

// const ToolbarShadowStyle = styled('div')(({ theme }) => ({
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: 24,
//     zIndex: -1,
//     margin: 'auto',
//     borderRadius: '50%',
//     position: 'absolute',
//     width: 'calc(100% - 48px)',
//     boxShadow: theme.customShadows.z8,
// }))

const StyleButtonDis = styled((props: ButtonProps) => (
    <Button variant="outlined" {...props} />
))(({ theme }) => ({
    borderColor: '#eee',
    '&.Mui-disabled': {
        borderColor: '#eee',
        color: '#454545'
    },
}))

const StyleButtonSafe = styled((props: ButtonProps) => (
    <Button variant="outlined" {...props} />
))(({ theme }) => ({
    borderColor: '#eee',
    '&.Mui-disabled': {
        borderColor: '#eee'
    }
}))

const StyleDownLoadSafe = styled((props: ButtonProps) => (
    <Button variant="outlined" {...props} />
))(({ theme }) => ({
    borderColor: theme.palette.warning.light,
    '&.Mui-disabled': {
        borderColor: theme.palette.warning.light
    }
}))

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}))
// ----------------------------------------------------------------------
let time: any = null
export default function MainHeader() {
    // const { safeboxAddr } = useSafebox()
    // const [safeAddr, setSafeAddr] = useState('--')
    // const isActive = useIsActive()
    const { isConnectZKSafe, setConnectZKSafe } = useSafeboInfo()
    const { currentNetwork, setCurrentNetwork,networks, chains, pluginReady } = useContracts()
    const [connectNum, setConnectNum] = useState(0)
    const { openLoading, closeLoading } = useContext(UserActionContext)
    const isActivating = useIsActivating()

    const chainId = useChainId()

    const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT)

    const accounts = useAccounts()

    const theme = useTheme()

    // const [isConnectZKSafe, setConnectZKSafe] = useState(false)

    // const { pathname } = useLocation()

    // const [currentNetword, setCurrentNetword] = useState < NetworkType > ({
    //     chainId: 137,
    //     title: 'Polygon',
    //     icon: PolygonIcon,
    //     enable: true,
    //     zkContract: {
    //         safeboxFactoryAddr: '0x5a93D9a81F1ee8368BaD0EEb0f653bB45bFc6329',
    //         zkpassAddr: '0xCDc902C17985f5d66A857F67a2BD6f5A29cE225d'
    //     }
    // })

    // const isDesktop = useResponsive('up', 'md')
    // const isBigScreen = useResponsive('up', 'xl')
    // const isLgScreen = useResponsive('up', 'lg')
    // const isHome = pathname === '/'

    const [anchorEl, setAnchorEl] = React.useState < null | HTMLElement > (null)
    const open = Boolean(anchorEl)
    const [isOpenMaxHeight, setOpenMaxHeight] = useState < null | HTMLElement > (null)

    const handleMaxHeightClose = () => {
        setOpenMaxHeight(null)

    }
    const handleDisconnect = async () => {
        if (metaMask?.deactivate) {
            await metaMask.deactivate()
        } else {
            await metaMask.resetState()
        }
        handleMaxHeightClose()
    }
    const handleClickAccount = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenMaxHeight(event.currentTarget)
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(null)
    }

    const selectNetwork = (netWork: NetworkType) => {
        // console.log('selectNetwork', netWork)
        setAnchorEl(null)
        handleSwitchNetwork(netWork.chainId)
        setCurrentNetwork({
            ...netWork
        })
        // console.log('selectNetwork currentNetwork', currentNetwork)
    }

    // const syncChainInfo = useCallback(() => {
    //     if (chainId) {
    //         console.log('header sycn chainId', chainId)
    //         window.postMessage({ chainId, type: 'syncChainInfo', msg: { chainId } }, '*')
    //     }
    // }, [chainId])

    // useEffect(() => {
    //     // 
    //     // handleSwitchNetwork(chainId)
    //     console.log('useEffect header', chainId)
    //     const newNetwork = getChainNetworkInfo(chainId, networks)
    //     setCurrentNetwork({
    //         ...newNetwork
    //     })
    //     syncChainInfo()
    // }, [chainId])

    // const alertPlugin = () =>{
        
    //     enqueueSnackbar('ZKSAFE extension is in review, it\'ll launch sooooon', { variant: 'error', autoHideDuration: 6000 })
    // }

    const handleDownload = () => {
        if (pluginReady) {
            window.open('https://chrome.google.com/webstore/detail/zksafe/nehdcpkmooagohgkdchblmnfljmnbihg')
        } else {
            setIsAlertDialog(true)
        }
    }

    const [isAlertDialog, setIsAlertDialog] = useState(false)
    const [alertContext, setAlertContext] = useState<string>('ZKSAFE extension is in review, it\'ll launch sooooon')

    const closeDialog = () =>{
        setIsAlertDialog(false)
    }

    const handleSwitchNetwork = async (chainId: number | undefined) => {
        try {
            // setLoading(true) 
            await metaMask.activate(getAddChainParameters(chainId || 137, chains))
            // console.log('switch', currentNetwork)
            setTimeout(async () => {
                await metaMask.connectEagerly().catch(() => {
                    console.debug('Failed to connect eagerly to metamask')
                })
            }, 500)
            // console.log(res)
            // setLoading(false)
        } catch (err) {
            console.error(err)
            // setLoading(false)
        }

        // context.setFirstValidConnector(['MetaMask', 'Infura'])
    }
    // useEffect(() => {
    //     if (accounts && safeboxAddr ) {
    //         setSafeAddr(shortAccount(safeboxAddr))
    //     }
    // },[accounts, safeboxAddr])
    const checkZKSAFEState = useCallback(() => {
        if (isConnectZKSafe) {
            clearInterval(time)
            return false
        }
        window.postMessage({ type: 'checkInstallZKSafe' }, '*')
        setConnectNum(connectNum + 1)
    }, [isConnectZKSafe, connectNum])
    const handleChangeConnectZKSafe = () => {
        setConnectZKSafe(true)
    }
    useEffect(() => {
        // window.removeEventListener('isInstall', handleChangeConnectZKSafe)
        window.addEventListener('isInstall', handleChangeConnectZKSafe)
        window.postMessage({ type: 'checkInstallZKSafe' }, '*')
    }, [])
    useEffect(() => {
        if (!isConnectZKSafe && connectNum < 3) {
            if (time) {
                clearInterval(time)
            }
            time = setInterval(checkZKSAFEState, 2000)
        }
    }, [isConnectZKSafe, checkZKSAFEState, connectNum])
    useEffect(() => {
        if (isConnectZKSafe && time) {
            setConnectNum(0)
            clearInterval(time)
        }
    }, [isConnectZKSafe])

    useEffect(() => {
        if (isActivating) {
            openLoading()
        } else {
            closeLoading()
        }
    }, [isActivating])

    const chainIsEnablePage = () => chainIsEnable(chainId, networks)
    

    return (
        <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent', position: 'relative' }}>
            <ToolbarStyle
                disableGutters
                sx={{
                    ...(isOffset && {
                        ...cssStyles(theme).bgBlur(),
                        height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
                    }),
                }}
            >
                <Container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                    maxWidth={'lg'}
                >
                    <Link to="#">
                        <Image src={Logo} sx={{ width: 180 }} />
                    </Link>

                    <Stack direction={'row'} justifyContent="flex-end" alignItems={'center'} spacing={3}>
                        {accounts && (!chainIsEnablePage()) && <Button variant="outlined" color="warning" onClick={e => handleSwitchNetwork(chainId) }>Switch Network</Button>}
                        {chainId && (chainIsEnablePage()) && <>
                            <StyleButtonSafe
                                color="success"
                                id="demo-customized-button"
                                aria-controls={open ? 'customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="outlined"
                                disableElevation
                                onClick={handleClick}
                                endIcon={<KeyboardArrowDownIcon sx={{ color: '#454545' }} />}
                            >
                                <Stack direction={'row'} spacing={1} alignItems="center" >
                                    <Image src={getChainIcon(chainId)} sx={{ width: 20 }} />
                                    <Typography variant='body1' sx={{ color: '#454545' }}>{currentNetwork.title}</Typography>
                                </Stack>
                            </StyleButtonSafe>
                            <StyledMenu
                                id="selector-network-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'selector-network-menu',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                {networks.map((v: NetworkType) => <MenuItem disabled={!v.enable} key={v.title} onClick={e => selectNetwork(v)} disableRipple>
                                    <Stack direction={'row'} spacing={1} alignItems="center">
                                        <Image src={getChainIcon(v.chainId)} sx={{ width: 24 }} />
                                        <Typography variant='body1'>{v.title}</Typography>
                                    </Stack>
                                </MenuItem>)}
                            </StyledMenu>
                        </>}
                        {isConnectZKSafe ? <StyleButtonDis disabled>
                            <Stack direction={'row'} spacing={1}>
                                <Image src={ZksafeIcon} sx={{ width: 20 }} />
                                <Typography variant='body1'>Connected</Typography>
                            </Stack>
                        </StyleButtonDis> :
                            !isConnectZKSafe && connectNum >= 3 ? <StyleDownLoadSafe variant="outlined" color="warning" onClick={handleDownload} sx={{ color: '#454545' }}>
                                <Stack direction={'row'} spacing={1}>
                                    <Image src={ZksafeIcon} sx={{ width: 20 }} />
                                    <Typography variant='body1'>Download</Typography>
                                </Stack>
                            </StyleDownLoadSafe> :
                                <StyleDownLoadSafe variant="outlined" color="warning" onClick={handleDownload} sx={{ color: '#454545' }} disabled>
                                    <Stack direction={'row'} spacing={1}>
                                        <Image src={ZksafeIcon} sx={{ width: 20 }} />
                                        <Typography variant='body1'>Connecting...</Typography>
                                    </Stack>
                                </StyleDownLoadSafe>
                        }
                        {accounts ? <StyleButtonSafe variant="outlined" color="success" onClick={handleClickAccount} sx={{ color: '#454545' }}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Image src={MetaMaskIcon} sx={{ width: 22 }} />
                                <Typography variant='body1'>{shortAccount(accounts ? accounts[0] : '')}</Typography>
                            </Stack>
                        </StyleButtonSafe> : <StyleButtonSafe variant="outlined" color="success" onClick={e => handleSwitchNetwork(chainId)} >
                            <Typography variant='body1'>Connect Wallet</Typography>
                        </StyleButtonSafe>}
                        <Menu
                            keepMounted
                            id="long-menu"
                            anchorEl={isOpenMaxHeight}
                            onClose={handleMaxHeightClose}
                            open={Boolean(isOpenMaxHeight)}
                        // PaperProps={{
                        //     style: {
                        //         maxHeight: 48 * 4.5,
                        //         width: '20ch',
                        //     },
                        // }}
                        >
                            <MenuItem
                                // selected={option === 'Pyxis'}
                                onClick={handleDisconnect}
                            >
                                Disconnect
                            </MenuItem>
                        </Menu>
                    </Stack>

                    {/* {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />} */}
                </Container>
            </ToolbarStyle>
            <Dialog open={isAlertDialog} onClose={closeDialog}>
                <Box sx={{ width: 500, p: 2 }}>
                    <DialogTitle sx={{ mx: 'auto', py: 2, textAlign: 'center' }}/>
                    <DialogContent sx={{ color: '#999', mx: 'auto', py: 2, textAlign: 'center' }}>
                        {alertContext}
                    </DialogContent>
                    <DialogActions>
                        <Button sx={{ color: '#fff' }} color="success" onClick={closeDialog} autoFocus variant="contained">
                    Close
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>

            {/* {isOffset && <ToolbarShadowStyle />} */}
        </AppBar>
    )
}
