// import { capitalCase } from 'change-case'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
// import { LoadingButton } from '@mui/lab'
import { Card, Stack, Button, Container, Box, Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions } from '@mui/material'
// routes
// import { useWeb3Context } from 'web3-react'
// import Iconify from '~/components/Iconify'
// import { PATH_AUTH } from '../../routes/paths'
// hooks
// import useAuth from '../../hooks/useAuth'
import useResponsive from '../../hooks/useResponsive'
// components
import Page from '../../components/Page'
// import Logo from '~/assets/zkSafe_logo.svg'
import Image from '../../components/Image'
// import { metaMask } from '~/connectors/metaMask'
// import { getAddChainParameters } from '~/config/chains'
import banner from '~/assets/banner.png'
// sections
// import { LoginForm } from '../../sections/auth/login'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}))

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}))

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}))

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}))

// ----------------------------------------------------------------------

export default function Login() {
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    // const { method } = useAuth()

    // const smUp = useResponsive('up', 'sm')

    const mdUp = useResponsive('up', 'md')
    // const context = useWeb3Context()
    // const { login } = useAuth()
    // const [isLoading, setLoading] = useState<boolean>(false)
    const handleDownload = () => {

        // window.open('https://metamask.io/')
    }
    // const handleConnect = () => {
    //     try {
    //         setLoading(true)
    //         await metaMask.activate(getAddChainParameters(137))
    //         setTimeout(async () => {
    //             await metaMask.connectEagerly().catch(() => {
    //                 console.debug('Failed to connect eagerly to metamask')
    //             })
    //         }, 500)
    //         console.log(res)
    //         setLoading(false)
    //     } catch(err) {
    //         console.error(err)
    //         setLoading(false)
    //     }
        
    // context.setFirstValidConnector(['MetaMask', 'Infura'])
    // }
    
    // useEffect(() => {
    //     if (!context.active && !context.error) {
    //         console.log(1, context)
    //         setLoading(false)
    //     } else if (context.error) {
    //         console.log(2, context)
    //         //error
    //         setLoading(false)
    //     } else {
    //         console.log(3, context)
    //         // success
    //         setLoading(false)
    //     }
    // }, [context])
    return (
        <Page title="Login" sx={{ backgroundColor: 'rgb(248, 251, 251)' }}>
            <RootStyle>
                <HeaderStyle>
                    {/* <Image src={Logo} sx={{ width: 180 }} /> */}
                    {/* {smUp && (
                        <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                            Don’t have an account? {''}
                            <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                                Get started
                            </Link>
                        </Typography>
                    )} */}
                </HeaderStyle>

                {/* {mdUp && (
                    <SectionStyle>
                        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                            Hi, Welcome Back
                        </Typography>
                        <Image
                            visibleByDefault
                            disabledEffect
                            src="/assets/illustrations/illustration_login.png"
                            alt="login"
                        />
                    </SectionStyle>
                )} */}

                <Container maxWidth="md" sx={{ height: '100vh' }}>
                    <Stack direction={'column'} justifyContent="center" sx={{ height: '100%' }}>
                        <Box>
                            <Image src={banner} sx={{ width: '100%' }} />
                        </Box>
                        <ContentStyle>
                        
                            {/* <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="h4" gutterBottom>
                                    Sign in to ZKSafebox
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
                            </Box>

                            <Tooltip title={capitalCase(method)} placement="right">
                                <>
                                    <Image
                                        disabledEffect
                                        src={`https://minimal-assets-api-dev.vercel.app/assets/icons/auth/ic_${method}.png`}
                                        sx={{ width: 32, height: 32 }}
                                    />
                                </>
                            </Tooltip>
                        </Stack> */}

                            {/* <Alert severity="info" sx={{ mb: 3 }}>
                            Use email : <strong>demo@minimals.cc</strong> / password :<strong> demo1234</strong>
                        </Alert> */}

                            {/* <LoginForm /> */}
                            <Button color="success" sx={{ fontSize: 26, width: 480, color: '#fff' }} variant="contained"  onClick={handleClickOpen}
                            >Connect Wallet</Button>

                            {/* {!smUp && (
                                <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                                Don’t have an account?{' '}
                                    <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                                    Get started
                                    </Link>
                                </Typography>
                            )} */}
                        </ContentStyle>
                    </Stack>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>You need to install wallet</DialogTitle>
                        {/* <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Let Google help apps determine location. This means sending anonymous location data to
                                Google, even when no apps are running.
                            </DialogContentText>
                        </DialogContent> */}
                        <DialogActions>
                            {/* <Button onClick={handleClose}>Disagree</Button> */}
                            <Button onClick={handleClose} autoFocus>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Container>
            </RootStyle>
        </Page>
    )
}
