import { useState, createContext, ReactNode, useEffect, useCallback } from 'react'
import { get } from '../utils/axiosRequest'
import { zksafeApi } from '../config/ApiEndpoint'

type ChainContextProps = {
    chains: any
    networks: any
    baseTokenList: any
    setChains: (state: any) => void
    setNetworks: (state: any) => void
    setBaseTokenList: (state: any) => void
    // createdChain: VoidFunction
}
type ChainProviderProps = {
    children: ReactNode
}
const initialState: ChainContextProps = {
    chains: {},
    networks: [],
    baseTokenList: [],
    setChains: () => {},
    setNetworks: () => {},
    setBaseTokenList: () => {},
    // setCurrentChainId:() => {},
}

const ChainContext = createContext(initialState)

// const { useAccounts, useProvider } = hooks

function ChainProvide({ children }: ChainProviderProps) {
    const [chains, setChains] = useState({})
    const [networks, setNetworks] = useState([])
    const [baseTokenList, setBaseTokenList] = useState([])
    // const [currentChainId, setCurrentChainId] = useState(137)


    const initial = useCallback(async () => {
        try {
            const result :any = await get(zksafeApi.getChainInfo)
            // console.log('get context chainInfo', result)
            // console.log('reslut', result.code === 0)
            if (result && result.code === 0) {
                setChains(result.data.chains)
                setNetworks(result.data.networks)
                setBaseTokenList(result.data.baseTokenList)
                // console.log('get content baseTokenList', result.data.baseTokenList)
            }
        } catch (err) {
            console.error(err)
        }
    }, [])

    useEffect(() => {
        initial()
    }, [initial])
    return (
        <ChainContext.Provider
            value={{
                chains,
                networks,
                baseTokenList,
                setChains,
                setNetworks,
                setBaseTokenList,
            }}
        >
            {children}
        </ChainContext.Provider>
    )
}
export { ChainProvide, ChainContext }
