// import { hooks } from '~/connectors/metaMask'
// import { useContracts } from '~/hooks/useContracts'
import { useState, createContext, ReactNode } from 'react'

type SafeboxContextProps = {
    isActivating: boolean
    isCreated: boolean
    isSetPassword: boolean
    isConnectZKSafe: boolean
    safeboxAddr: string
    setActivatState: (state: boolean) => void
    setIsCreated: (state: boolean) => void
    setIsSetPassword: (state: boolean) => void
    setConnectZKSafe: (state: boolean) => void
    setSafeboxAddr: (addr: string) => void
    // createdSafebox: VoidFunction
}
type SafeboxProviderProps = {
    children: ReactNode
}
const initialState: SafeboxContextProps = {
    isActivating: false,
    isCreated: false,
    isConnectZKSafe: false,
    isSetPassword: false,
    safeboxAddr: '',
    setActivatState: () => { },
    setIsCreated: () => { },
    setIsSetPassword: () => { },
    setSafeboxAddr: () => { },
    setConnectZKSafe: () => { },
}

const SafeboxContext = createContext(initialState)

// const { useAccounts, useProvider } = hooks

function SafeboxProvide({ children }: SafeboxProviderProps) {
    // const { safeboxFactory, eps } = useContracts()
    // const accounts = useAccounts()
    // const account = accounts ? accounts[0] : ''
    // console.log(account)
    // const provider = useProvider()
    // console.log(provider)
    const [isActivating, setActivatState] = useState(false)
    const [safeboxAddr, setSafeboxAddr] = useState('')
    const [isCreated, setIsCreated] = useState(false)
    const [isSetPassword, setIsSetPassword] = useState(false)
    const [isConnectZKSafe, setConnectZKSafe] = useState(false)
    // const createdSafebox = () => {
    // }
    // const checkCreatedSafebox = useCallback(async () => {
    //     const existSafeboxAddr = await safeboxFactory.userToSafebox(account)
    //     setIsCreated(existSafeboxAddr === safeboxAddr)
    // }, [account, safeboxFactory, safeboxAddr])

    // const checkPswState = useCallback(async () => {
    //     const res = await eps.pwdhashOf(account)
    //     setIsSetPassword(res.toString() !== '0')
    // }, [account, eps])

    // const getSafeboxAddr = useCallback(async () => {
    //     try {
    //         const res = await safeboxFactory.getSafeboxAddr(account)
    //         setSafeboxAddr(res)
    //     } catch (err) {
    //         console.error(err)
    //     }
    // }, [account, safeboxFactory])

    // useEffect(() => {
    //     getSafeboxAddr()
    // }, [getSafeboxAddr])

    // useEffect(() => {
    //     checkCreatedSafebox()
    // }, [checkCreatedSafebox])

    // useEffect(() => {
    //     checkPswState()
    // }, [checkPswState])
    return (
        <SafeboxContext.Provider
            value={{
                isCreated,
                isSetPassword,
                isActivating,
                isConnectZKSafe,
                safeboxAddr,
                setActivatState,
                setIsCreated,
                setIsSetPassword,
                setSafeboxAddr,
                setConnectZKSafe,
                // createdSafebox,
            }}>
            {children}
        </SafeboxContext.Provider>
    )
}
export { SafeboxProvide, SafeboxContext }