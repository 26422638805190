import * as ethers from 'ethers'
import { useState, useEffect, useContext } from 'react'
import { NetworkType, getChainNetworkInfo } from '~/config/chains'
// import PasswordServiceJSON from '~/contracts/pws/PasswordService.sol/PasswordService.json'
import SafeboxFactoryJSON from '~/contracts/v1/SafeboxFactory.json'
import ZKPassJSON from '~/contracts/v1/ZKPass.json'
import ERC721JSON from '~/token/ERC721.json'
import Safebox from '~/contracts/v1/Safebox.json'
import { hooks } from '~/connectors/metaMask'
import { ChainContext } from '../contexts/ChainContext'

const useChains = () => useContext(ChainContext)
// import { type } from 'os'

const { useProvider } = hooks

// const ZKPass = '0xCDc902C17985f5d66A857F67a2BD6f5A29cE225d'
// const safeboxFactoryAddr = '0x5a93D9a81F1ee8368BaD0EEb0f653bB45bFc6329'

// const providerURL = 'https://matic-mainnet.chainstacklabs.com'

export type ZKContractsType = {
    deployer: string
    safeboxFactoryAddr: string
    zkpassAddr: string
}


export function useContracts() {
    const { useChainId } = hooks
    const { chains, networks, baseTokenList } = useChains()

    const chainId = useChainId() || 137
    const networkInfo = getChainNetworkInfo(chainId, networks)


    const [pluginReady, setPluginReady] = useState<boolean>(true)

    const [currentNetwork, setCurrentNetwork] = useState<NetworkType>(networkInfo)

    const [currentBaseTokenList, setCurrentBaseTokenList] = useState<any>(baseTokenList[chainId])

    const provider = useProvider()
    let safeboxFactory
    let zkPass
    let getSafeboxFactory
    let getERC721Factory

    if (provider) {
        const signer = (provider as any).getSigner()
        safeboxFactory = (new ethers.Contract(networkInfo.zkContract.safeboxFactoryAddr, SafeboxFactoryJSON.abi, signer))
        zkPass = (new ethers.Contract(networkInfo.zkContract.zkpassAddr, ZKPassJSON.abi, signer))
        getSafeboxFactory = (safeboxAddress: string) => (new ethers.Contract(safeboxAddress, Safebox.abi, signer))
        getERC721Factory = (nftAddress: string) => (new ethers.Contract(nftAddress, ERC721JSON.abi, signer))
    }

    // const usdt = (new ethers.Contract(usdtAddr, ERC20JSON.abi, signer))
    // const usdc = (new ethers.Contract(usdcAddr, ERC20JSON.abi, signer))
    // const dai = (new ethers.Contract(daiAddr, ERC20JSON.abi, signer))
    // const weth = (new ethers.Contract(wethAddr, ERC20JSON.abi, signer))
    // const matic = (new ethers.Contract(maticAddr, ERC20JSON.abi, signer))
    // const getSafeboxFactory = (safeboxAddress: string) => (new ethers.Contract(safeboxAddress, Safebox.abi, signer))
    // const getERC721Factory = (nftAddress: string) => (new ethers.Contract(nftAddress, ERC721JSON.abi, signer))


    useEffect(() => {
        if (provider) {
            // console.log('contract', currentNetwork)
            const signer = (provider as any).getSigner()
            safeboxFactory = (new ethers.Contract(networkInfo.zkContract.safeboxFactoryAddr, SafeboxFactoryJSON.abi, signer))
            zkPass = (new ethers.Contract(networkInfo.zkContract.zkpassAddr, ZKPassJSON.abi, signer))
            getSafeboxFactory = (safeboxAddress: string) => (new ethers.Contract(safeboxAddress, Safebox.abi, signer))
            getERC721Factory = (nftAddress: string) => (new ethers.Contract(nftAddress, ERC721JSON.abi, signer))
        }

    }, [currentNetwork])

    useEffect(() => {
        const networkInfo = getChainNetworkInfo(chainId, networks)
        setCurrentNetwork({
            ...networkInfo
        })
        setCurrentBaseTokenList(baseTokenList[chainId])
    }, [useChainId(), baseTokenList])



    return {
        safeboxFactory,
        zkPass,
        getSafeboxFactory,
        getERC721Factory,
        currentNetwork,
        setCurrentNetwork,
        networks,
        chains,
        baseTokenList,
        currentBaseTokenList,
        pluginReady,
    }
}