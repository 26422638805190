import { ReactNode, createContext, useState } from 'react'

type UserActionContextProps = {
    isLoading: boolean
    isCreated: boolean
    openLoading: VoidFunction
    closeLoading: VoidFunction
    createdSafebox: VoidFunction
}

const initialState: UserActionContextProps = {
    isLoading: false,
    isCreated: false,
    openLoading: () => { },
    closeLoading: () => { },
    createdSafebox: () => { }
}

const UserActionContext = createContext(initialState)
type UserActionProviderProps = {
    children: ReactNode;
}

function UserActionProvide({ children }: UserActionProviderProps) {
    const [loadingState, setLoadingState] = useState(false)
    const [isCreated, setCreatedState] = useState(false)

    const openLoading = () => {
        setLoadingState(true)
    }
    const closeLoading = () => {
        setLoadingState(false)
    }
    const createdSafebox = () => {
        setCreatedState(true)
    }
    return (
        <UserActionContext.Provider
            value={{
                isLoading: loadingState,
                openLoading,
                closeLoading,
                isCreated,
                createdSafebox
            }}>
            {children}
        </UserActionContext.Provider>
    )
}
export { UserActionProvide, UserActionContext }