import type { AddEthereumChainParameter } from '@web3-react/types'
import { ZKContractsType } from '~/hooks/useContracts'
import EthIcon from '~/assets/Ethereum.svg'
import PolygonIcon from '~/assets/Polygon.svg'
import OptimismIcon from '~/assets/Optimism.svg'
import ArbitrumsvgIcon from '~/assets/Arbitrumsvg.svg'
import BSCIcon from '~/assets/BSC.svg'



const ETH: AddEthereumChainParameter['nativeCurrency'] = {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
}

const MATIC: AddEthereumChainParameter['nativeCurrency'] = {
    name: 'Matic',
    symbol: 'MATIC',
    decimals: 18,
}

const BNB: AddEthereumChainParameter['nativeCurrency'] = {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
}



interface BasicChainInformation {
    urls: string[]
    name: string
}

interface ExtendedChainInformation extends BasicChainInformation {
    nativeCurrency: AddEthereumChainParameter['nativeCurrency']
    blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls']
}

export const CHAINS: { [chainId: number]: BasicChainInformation | ExtendedChainInformation } = {
    1: {
        urls: [
            process.env.infuraKey ? `https://mainnet.infura.io/v3/${process.env.infuraKey}` : '',
            process.env.alchemyKey ? `https://eth-mainnet.alchemyapi.io/v2/${process.env.alchemyKey}` : '',
            'https://cloudflare-eth.com',
        ].filter((url) => url !== ''),
        name: 'Mainnet',
        nativeCurrency: ETH,
        blockExplorerUrls: ['https://etherscan.io/'],
    },
    // Polygon
    137: {
        urls: [
            'https://matic-mainnet.chainstacklabs.com',
        ].filter((url) => url !== ''),
        name: 'Polygon Mainnet',
        nativeCurrency: MATIC,
        blockExplorerUrls: ['https://polygonscan.com'],
    },
    // BNB
    56: {
        urls: [
            'https://bsc-dataseed4.defibit.io/',
        ].filter((url) => url !== ''),
        name: 'BNB Chain',
        nativeCurrency: BNB,
        blockExplorerUrls: ['https://bscscan.com'],
    }
}

function isExtendedChainInformation(
    chainInformation: BasicChainInformation | ExtendedChainInformation
): chainInformation is ExtendedChainInformation {
    return !!(chainInformation as ExtendedChainInformation).nativeCurrency
}

export function getAddChainParameters(chainId: number, chains: { [chainId: number]: BasicChainInformation | ExtendedChainInformation }): AddEthereumChainParameter | number {
    const chainInformation = chains[chainId]
    if (isExtendedChainInformation(chainInformation)) {
        return {
            chainId,
            chainName: chainInformation.name,
            nativeCurrency: chainInformation.nativeCurrency,
            rpcUrls: chainInformation.urls,
            blockExplorerUrls: chainInformation.blockExplorerUrls,
        }
    } else {
        return chainId
    }
}

export const URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{ [chainId: number]: string[] }>(
    (accumulator, chainId) => {
        const validURLs: string[] = CHAINS[Number(chainId)].urls

        if (validURLs.length) {
            accumulator[Number(chainId)] = validURLs
        }

        return accumulator
    },
    {}
)

// export const URLS = (chains: { [chainId: number]: BasicChainInformation | ExtendedChainInformation }) => Object.keys(chains).reduce<{ [chainId: number]: string[] }>(
//     (accumulator, chainId) => {
//         const validURLs: string[] = chains[Number(chainId)].urls

//         if (validURLs.length) {
//             accumulator[Number(chainId)] = validURLs
//         }

//         return accumulator
//     },
//     {}
// )

export type NetworkType = {
    chainId: number | undefined
    title: string
    icon: string
    enable: boolean
    zkContract: ZKContractsType
}

// export const networkInfo: NetworkType[] = [
//     {
//         chainId: 1,
//         title: 'Ethereum',
//         icon: EthIcon,
//         enable: false,
//         zkContract: {
//             safeboxFactoryAddr: '0x5a93D9a81F1ee8368BaD0EEb0f653bB45bFc6329',
//             zkpassAddr: '0xCDc902C17985f5d66A857F67a2BD6f5A29cE225d'
//         }
//     },
//     {
//         chainId: 137,
//         title: 'Polygon',
//         icon: PolygonIcon,
//         enable: true,
//         zkContract: {
//             safeboxFactoryAddr: '0x5a93D9a81F1ee8368BaD0EEb0f653bB45bFc6329',
//             zkpassAddr: '0xCDc902C17985f5d66A857F67a2BD6f5A29cE225d'
//         }
//     },
//     {
//         chainId: 56,
//         title: 'BSC',
//         icon: BSCIcon,
//         enable: true,
//         zkContract: {
//             safeboxFactoryAddr: '0xA4260E6f2532c29B69ac6015108c11434831eb14',
//             zkpassAddr: '0x930Db107c074E3f66C923180100F74fF4AABEaa5'
//         }
//     },
//     {
//         chainId: 10,
//         title: 'Optimism',
//         icon: OptimismIcon,
//         enable: false,
//         zkContract: {
//             safeboxFactoryAddr: '0x5a93D9a81F1ee8368BaD0EEb0f653bB45bFc6329',
//             zkpassAddr: '0xCDc902C17985f5d66A857F67a2BD6f5A29cE225d'
//         }
//     },
//     {
//         chainId: 42161,
//         title: 'Arbitrum',
//         icon: ArbitrumsvgIcon,
//         enable: false,
//         zkContract: {
//             safeboxFactoryAddr: '0x5a93D9a81F1ee8368BaD0EEb0f653bB45bFc6329',
//             zkpassAddr: '0xCDc902C17985f5d66A857F67a2BD6f5A29cE225d'
//         }
//     }
// ]

export const defaultNetwork: NetworkType = {
    chainId: 137,
    title: 'Polygon',
    icon: PolygonIcon,
    enable: true,
    zkContract: {
        deployer: '0x45914F46006c3e6fD7d68064370fB6e1f5564550',
        safeboxFactoryAddr: '0x8528d5a340Bef2e50844CDABdFa21bC6B57c3982',
        zkpassAddr: '0x9802cBf6480FE2a0c69740Bc8008739DfF1E7CEF'
    }
}


export const chainIsEnable = (chainId: number | undefined, networks: NetworkType[]) => {
    const netWork = networks.find(network => network.chainId === chainId && network.enable)
    if (netWork) {
        return true
    }
    return false
}


export const getChainIcon = (chainId: number | undefined) => {
    switch (chainId) {
        case 1: {
            return EthIcon
        }
        case 10: {
            return OptimismIcon
        }
        case 56: {
            return BSCIcon
        }
        case 137: {
            return PolygonIcon
        }
        case 42161: {
            return ArbitrumsvgIcon
        }
    }
    return EthIcon
}

export function getChainNetworkInfo(chainId: number | undefined, networks: NetworkType[]): NetworkType {
    return networks.find(network => network.chainId === chainId) || defaultNetwork
}

// export function getChainContracts(chainId: number | undefined, networks: NetworkType[]): ZKContractsType {
//     const netWork = networks.find(network => network.chainId === chainId)
//     if (netWork) {
//         return netWork.zkContract
//     } else {
//         return defaultNetwork.zkContract
//     }
// }
