import { ReactNode } from 'react'
// import { SupportedChainId } from '~/utils/chains'
// import Web3Provider, { Connectors } from 'web3-react'
// const { InjectedConnector, NetworkOnlyConnector } = Connectors
// const MetaMask = new InjectedConnector({ supportedNetworks: [SupportedChainId.POLYGON, SupportedChainId.POLYGON_MUMBAI] })
// const Infura = new NetworkOnlyConnector({
//     providerURL: 'https://matic-mainnet.chainstacklabs.com'
// })
// const connectors = { MetaMask, Infura }
// export default function Web3ReactProvider({ children }: { children: ReactNode }) {
//     // useEagerlyConnect()
//     // const connections = useOrderedConnections()
//     // const connectors: [Connector, Web3ReactHooks][] = connections.map(({ hooks, connector }) => [connector, hooks])

//     // const key = useMemo(() => connections.map(({ type }: Connection) => getConnectionName(type)).join('-'), [connections])

//     return (
//         <Web3Provider connectors={connectors}>
//             {children}
//         </Web3Provider>
//     )
// }
import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core'
import { MetaMask } from '@web3-react/metamask'
import { Network } from '@web3-react/network'
import { WalletConnect } from '@web3-react/walletconnect'
import { coinbaseWallet, hooks as coinbaseWalletHooks } from '~/connectors/coinbaseWallet'
import { hooks as metaMaskHooks, metaMask } from '~/connectors/metaMask'
import { hooks as networkHooks, network } from '~/connectors/network'
import { hooks as walletConnectHooks, walletConnect } from '~/connectors/walletConnect'
// import { getName } from '~/utils'

const connectors: [MetaMask | WalletConnect | CoinbaseWallet | Network, Web3ReactHooks][] = [
    [metaMask, metaMaskHooks],
    [walletConnect, walletConnectHooks],
    [coinbaseWallet, coinbaseWalletHooks],
    [network, networkHooks],
]

// function Child() {
//     const { connector } = useWeb3React()
//     console.log(`Priority Connector is: ${getName(connector)}`)
//     return null
// }

export default function Web3Provider({ children }: { children: ReactNode }) {
    return (
        <Web3ReactProvider connectors={connectors}>
            {/* <Child /> */}
            {children}
        </Web3ReactProvider>
    )
}
