import { Outlet } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
// components
// import Logo from '../components/Logo'
import Image from '../components/Image'
import Logo from '~/assets/zkSafe_logo.svg'
// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    left: 0,
    lineHeight: 0,
    width: '100%',
    position: 'absolute',
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5, 5, 0)
    }
}))

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
    return (
        <>
            <HeaderStyle>
                {/* <Image src={Logo} sx={{ width: 100 }}/> */}
            </HeaderStyle>
            <Outlet />
        </>
    )
}
