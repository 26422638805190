import axios from 'axios'
import { ZKSAFE_API_HOST } from '~/config/ApiEndpoint'

export class AxiosRequest { }

axios.defaults.timeout = 100000
axios.defaults.baseURL = ZKSAFE_API_HOST

axios.interceptors.request.use(
    (config) => {
        config.data = JSON.stringify(config.data)
        config.headers = {
            'Content-Type': 'application/json',
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    (response) => {
        if (response.data.errCode === 2) {
            console.log('Expired')
        }
        return response
    },
    (error) => {
        console.log('err', error)
    }
)

export function get(url: any, params = {}) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params,
            })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function post(url: any, data: any) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(
            (response) => {
                resolve(response.data)
            },
            (err) => {
                reject(err)
            }
        )
    })
}

export function patch(url: any, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data).then(
            (response) => {
                resolve(response.data)
            },
            (err) => {
                reject(err)
            }
        )
    })
}

export function put(url: any, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then(
            (response) => {
                resolve(response.data)
            },
            (err) => {
                reject(err)
            }
        )
    })
}

export default function (fecth: any, url: any, param: any) {
    return new Promise((resolve, reject) => {
        switch (fecth) {
            case 'get':
                console.log('begin a get request,and url:', url)
                get(url, param)
                    .then(function (response) {
                        resolve(response)
                    })
                    .catch(function (error) {
                        console.log('get request GET failed.', error)
                        reject(error)
                    })
                break
            case 'post':
                post(url, param)
                    .then(function (response) {
                        resolve(response)
                    })
                    .catch(function (error) {
                        console.log('get request POST failed.', error)
                        reject(error)
                    })
                break
            default:
                break
        }
    })
}
